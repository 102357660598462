import React from "react"
import { FormattedMessage } from "react-intl"
import ReactNavbar from "./navbar"

import {
  Jumbotron,
  Container,
} from "reactstrap"

import "./header-about.css"

const HeaderAbout = () => (
  <div>
    <ReactNavbar />
    <Jumbotron fluid id="header-about-bg" style={{marginBottom: "3rem"}}>
      <Container fluid>
        {/* <h1 className="mass-intro">
                <FormattedMessage id="org.massnet.components.header.mass" />
              </h1> */}
        <p className="mass-desc">
          <FormattedMessage id="org.massnet.components.header.about_desc.title" />
        </p>
      </Container>
    </Jumbotron>
  </div>
)

export default HeaderAbout
