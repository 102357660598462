import React from "react"
import PropTypes from "prop-types"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FaqAll from "../components/faq-all"
import ContactUs from "../components/contact-us"
import HeaderAbout from "../components/header-about"
import FooterNav from "../components/footer-nav"

const AboutUsPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <HeaderAbout />
    <SEO title="FAQ | MASS Blockchain Consensus Engine" keywords={[`MASS Blockchain Consensus Engine`]} lang={locale}/>
    <FaqAll />
    <ContactUs />
    <FooterNav />
  </Layout>
)

AboutUsPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

export default AboutUsPage
